import React from 'react';
import { graphql } from 'gatsby';
import { MediaMatcher, MediaServerRender } from 'react-media-match';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Modi from '../src/components/Modi/Modi';
import Layout from '../src/components/Layout/Layout';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import Loki from '../src/components/Loki/lokipane';
import { Freya } from '../src/components/Freya/Freya';
import H4 from '../src/components/Titles/TitleH4single/TitleH4single';
import H3 from '../src/components/Titles/TitleH3/TitleH3';
import { Fenrir } from '../src/components/Fenrir/Fenrir';
import Ymir from '../src/components/Ymir/Ymir';
import Thor from '../src/components/Thor/Thor';
import Hod from '../src/components/Hod/Hod';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import SEO from '../src/helpers/seo';
import buildFunnelUrl from '../src/helpers/buildFunnelUrl';
import tt from '../src/helpers/translation';

import './hotel_roulette.scss';

class Roulette extends React.Component {
  state = {};

  heimdallData = {
    name: this.props.data.allRouletteHeaderBlock.edges[0].node.name,
    image: {
      url: this.props.data.allRouletteHeaderBlock.edges[0].node.localImage.childImageSharp.fluid,
      alt: this.props.data.allRouletteHeaderBlock.edges[0].node.alt,
    },
    imageMobile: {
      url: this.props.data.allRouletteHeaderBlock.edges[0].node.localImage.childImageSharp.fluid,
      alt: this.props.data.allRouletteHeaderBlock.edges[0].node.alt,
    },
  };

  modiData = {
    Text: this.props.data.allRouletteTitleBlock.edges[0].node.sub_title,
    classContainer: 'no-image',
    // button1: {
    //   cta: tt('/hoteles/check-in-online', this.props.pageContext.locale),
    //   size: 'small',
    //   color: 'white',
    //   ctaText: tt('CHECKIN ONLINE', this.props.pageContext.locale),
    // },
    button2: {
      cta: `${buildFunnelUrl(
        this.props.pageContext.locale,
        'hotels'
      )}?_ga=2.76409685.1700126798.1640597527-1648847946.1639043608`,
      size: 'small',
      color: 'orange',
      ctaText: tt('RESERVAR AHORA', this.props.pageContext.locale),
    },
  };

  thorData = {
    title: this.props.data.allListBeneficiosRoulette.edges[0].node.title,
    subtitle: this.props.data.allListBeneficiosRoulette.edges[0].node.subtitle,
    cards: [
      {
        Icon: this.props.data.allListBeneficiosRoulette.edges[0].node.cards._0.Icon,
        titleIcon: this.props.data.allListBeneficiosRoulette.edges[0].node.cards._0.titleIcon,
        subtitleIcon: this.props.data.allListBeneficiosRoulette.edges[0].node.cards._0.subtitleIcon,
      },
      {
        Icon: this.props.data.allListBeneficiosRoulette.edges[0].node.cards._1.Icon,
        titleIcon: this.props.data.allListBeneficiosRoulette.edges[0].node.cards._1.titleIcon,
        subtitleIcon: this.props.data.allListBeneficiosRoulette.edges[0].node.cards._1.subtitleIcon,
      },
      {
        Icon: this.props.data.allListBeneficiosRoulette.edges[0].node.cards._2.Icon,
        titleIcon: this.props.data.allListBeneficiosRoulette.edges[0].node.cards._2.titleIcon,
        subtitleIcon: this.props.data.allListBeneficiosRoulette.edges[0].node.cards._2.subtitleIcon,
      },
    ],
  };

  lokiData = {
    title: tt('GALERIA DE FOTOS', this.props.pageContext.locale),
    arrayImg: this.props.data.allRouletteGallery.edges
      .map((block) => ({
        img: block.node.localImage ? block.node.localImage.childImageSharp.fluid : null,
        altImage: block.node.image_alt,
      }))
      .filter((item) => item.img !== null),

    bigArrayImg: this.props.data.allRouletteGallery.edges
      .map((block) => ({
        img: block.node.localImage ? block.node.localImage.childImageSharp.fluid : null,
        altImage: block.node.image_alt,
      }))
      .filter((item) => item.img !== null),
  };

  fenrirData = {
    list: this.props.data.allRouletteTextSagaBlock.edges[0].node.summary,
    title: this.props.data.allRouletteTextSagaBlock.edges[0].node.title,
  };

  ymirData = {
    cards: [
      {
        title: tt('HORARIOS Y CALENDARIO', this.props.pageContext.locale),
        buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
        link: tt('/horarios-calendario', this.props.pageContext.locale),
        icon: 'calendar',
      },
      {
        title: tt('CÓMO LLEGAR AL RESORT', this.props.pageContext.locale),
        buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
        link: tt('/destino/planificar/como-llegar-a-portaventura', this.props.pageContext.locale),
        icon: 'LOCATION',
      },
      {
        title: tt('DESCUENTOS EN ENTRADAS ONLINE', this.props.pageContext.locale),
        buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
        link: tt('/promociones', this.props.pageContext.locale),
        icon: 'Methodofpayment',
      },
    ],
  };

  freyaData = {
    title: this.props.data.allRouletteMosaicTitle.edges[0].node.title,
    cards: this.props.data.allRouletteThreeBlock.edges.map((e) => ({
      image: e.node.localImage.childImageSharp.fluid,
      altImage: e.node.image_alt,
      title: e.node.title,
      imgsize: 'promoted',
      buttons: {
        cta: e.node.buttons.cta,
        size: 'alone',
        color: 'white',
        ctaText: e.node.buttons.ctaText,
      },
    })),
  };

  render() {
    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
        options={this.props.pageContext.options}
      >
        <SEO
          title={this.props.data.allRouletteSeoData.edges[0].node._0.title}
          description={this.props.data.allRouletteSeoData.edges[0].node._1.description}
          imageRich={this.props.data.allRouletteRichData.edges[0].node.rich_markup.imgRich}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />

        <Hod
          data={this.props.data.allRouletteRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          // img={this.props.data.allRouletteRichData.edges[0].node.rich_markup.imgRich}
          hardcoded={
            this.props.data.allHotelRouletteDetailsRich.edges.length &&
            this.props.data.allHotelRouletteDetailsRich.edges[0].node.richmark
          }
        />
        <div className="is-fullWidth">
          <Heimdall data={this.heimdallData} />
          <div className="general-index">
            <div className="roulette-content">
              <Modi data={this.modiData} />
              <BlueArrow />
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allRouletteBreadcrumbBlock.edges[0].node.name}
              />
              <Thor data={this.thorData} />
              <H3 data={this.lokiData} />
              <Loki data={this.lokiData} />
              <div className="roulette-list-container">
                <H3 data={this.fenrirData} />
                <Fenrir data={this.fenrirData} />
              </div>
              <Ymir data={this.ymirData} />
              <div className="promotions-content">
                <H4 title={this.freyaData.title} />
                <Freya data={this.freyaData} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

Roulette.propTypes = {};

export default Roulette;

export const pageQuery = ({ data, pathContext: { locale } }) => graphql`
  query hotel_roulette($locale: String!) {
    allRouletteHeaderBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allRouletteSeoData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allRouletteTitleBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          sub_title
        }
      }
    }
    allListBeneficiosRoulette(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          subtitle
          cards {
            _0 {
              Icon
              titleIcon
              subtitleIcon
            }
            _1 {
              Icon
              titleIcon
              subtitleIcon
            }
            _2 {
              Icon
              titleIcon
              subtitleIcon
            }
          }
        }
      }
    }
    allRouletteTextSagaBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          summary
        }
      }
    }
    allRouletteMosaicTitle(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
        }
      }
    }
    allRouletteThreeBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          tag
          title
          image_alt
          buttons {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allRouletteGallery(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    allRouletteGalleryTitle(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allRouletteBreadcrumbBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
        }
      }
    }
    allHotelRouletteDetailsRich(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          richmark
        }
      }
    }
    allRouletteRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            color_css_class
            og_description
            og_title
            rich_markup_type
            imgRich
          }
        }
      }
    }
  }
`;
